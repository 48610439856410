<template>
  <!-- eslint-disable -->
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="4">
        <v-card class="elevation-0 cardstyle">
          <v-img max-height="400" :contain=true
                 rel="preload" class="white--text align-end" src="../assets/letter.png"
                 @load="imageLoaded">

          </v-img>
          <v-card-title :class="fontSize" v-if="imgload">
            <v-spacer></v-spacer>
            ColorizeThis AS
          </v-card-title>
          <v-card-text flat>

            <p v-if="imgload" class="text-right font-weight-bold">admin@colorizethis.no</p>

          </v-card-text>
          <v-card-actions>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>

export default {
  data() {
    return {
      imgload: false
    }
  },
  watch: {
    imgload() {
      // console.log('loaded')
    }
  },
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'text-h5'
        case 'sm':
          return 'text-h5'
        case 'md':
          return 'text-h5'
        default:
          return 'text-h4'
      }
    }
  },
  methods: {
    imageLoaded() {
      setTimeout(() => {
        this.imgload = true
      }, 50)
    }
  }
}
</script>
<style>
.cardstyle {
  max-width: 700px;
  min-width: 300px;
  padding-left: 20px;
  padding-right: 20px
}

.lepborder {
  border: 2px red solid;
}

#landingPage {
  margin-top: 30vh;
  margin-left: 1vh;
  margin-right: 1vh;
}
</style>
<style scoped>

</style>
