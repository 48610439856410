import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#263238',
        accent: '#D84315',
        secondary: '#616161',
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FFA7A7',
        error: '#FF5252',
        disabled: '#C5C5C5',
        gray: '#919191',
        dark_background: colors.grey.lighten3
      }
    }
  }
})
